import React from 'react';
import Seo from '@/components/Seo';
import Layout from '@/components/Layout';
import Typography from '@/components/Typography';
import Steps from '@/components/Steps';
import { PrivacyPage } from '@/utils/data/seo';

const Page = () => {
  return (
    <Layout>
      <div className="container px-4 py-5 text-dark-gray md:pb-17.5 md:pt-15 lg:max-w-[1130px]">
        <Typography as="h1" variant="header-2" className="pb-7.5">
          Polityka prywatności serwisu sprawdz-kosztorys.pl
        </Typography>
        <div className="space-y-7.5">
          <div className="space-y-3">
            <Typography as="h2" variant="header-3">
              § I Przetwarzanie danych osobowych
            </Typography>
            <ol className="ml-4 list-outside list-decimal space-y-3">
              <li className="ml-3 space-y-1.5">
                <Typography as="h3" variant="body-text-1">
                  ADMINISTRATOR DANYCH OSOBOWYCH
                </Typography>
                <Typography as="p" variant="body-text-2" className="ml-2">
                  Administratorem danych osobowych jest Helpfind Sp. z o.o.
                  wpisaną do rejestru przedsiębiorców prowadzonym przez Sąd
                  Rejonowy Poznań - Nowe Miasto i Wilda w Poznaniu, IX Wydział
                  Gospodarczy KRS pod numerem 0000385405, z siedzibą w Ostrowie
                  Wielkopolskim, 63-400, ul. Pułaskiego 11, NIP 622-276-35-69,
                  Regon 301735830. Administrator jest jednocześnie operatorem
                  serwisu sprawdz-kosztorys.pl
                </Typography>
              </li>
              <li className="ml-3 space-y-1.5">
                <Typography as="h3" variant="body-text-1">
                  CEL PRZETWARZANIA DANYCH OSOBOWYCH
                </Typography>
                <Typography as="p" variant="body-text-2" className="ml-2">
                  Dane osobowe są przetwarzane, aby w sposób prawidłowy została
                  wykonana umowa o świadczenie usług drogą elektroniczną.
                </Typography>
              </li>
              <li className="ml-3 space-y-1.5">
                <Typography as="h3" variant="body-text-1">
                  PODSTAWA PRAWNA
                </Typography>
                <Typography as="p" variant="body-text-2" className="ml-2">
                  Prawną podstawą do przetwarzania danych w ramach realizacji
                  umowy o świadczenie usług drogą elektroniczną są następujące
                  przepisy:
                  <ul className="ml-8 list-outside list-disc">
                    <li>
                      art. 6 ust. 1 lit. b RODO - marketing bezpośredni ramach
                      świadczonych usług przez Administratora
                    </li>
                    <li>
                      art. 6 ust. 1 pkt f RODO - kontakt z Użytkownikiem w celu
                      przedstawienia oferty. To w jaki sposób Administrator
                      kontaktuje się z Użytkownikiem zależy od rodzaju
                      pozostawionych danych.
                    </li>
                  </ul>
                </Typography>
              </li>
              <li className="ml-3 space-y-1.5">
                <Typography as="h3" variant="body-text-1">
                  KONTAKT Z ADMINISTRATOREM DANYCH OSOBOWYCH
                </Typography>
                <Typography as="p" variant="body-text-2" className="ml-2">
                  Z Administratorem danych osobowych można się skontaktować
                  wysyłając wiadomość na e- maila na iod@helpfind.pl,
                  telefonicznie na numer 616 350 050 lub tradycyjną drogą
                  pocztową - ul. Pułaskiego 11, 63-400 Ostrów Wielkopolski.
                </Typography>
              </li>
              <li className="ml-3 space-y-1.5">
                <Typography as="h3" variant="body-text-1">
                  ŹRÓDŁO I RODZAJ DANYCH
                </Typography>
                <Typography as="p" variant="body-text-2" className="ml-2">
                  Administrator gromadzi dane, które są konieczne do
                  zrealizowania usług oferowanych w ramach Serwisu. Dane są
                  powierzane Konsultantowi Call Center w celu wykonania
                  bezpłatnej analizy kosztorysu naprawy pojazdu.
                </Typography>
              </li>
              <li className="ml-3 space-y-1.5">
                <Typography as="h3" variant="body-text-1">
                  ODBIORCY DANYCH
                </Typography>
                <Typography as="p" variant="body-text-2" className="ml-2">
                  Dane osobowe w bezpośredni sposób trafiają do Administratora.
                  Mogą być na podstawie wyrażonej przez Użytkownika zgody
                  przekazywane Partnerom Administratora w celu realizacji
                  usługi.
                </Typography>
                <Typography as="p" variant="body-text-2" className="ml-2">
                  Dane osobowe mogą być przekazywane na żądanie prokuratury,
                  sądu, policji lub innych do tego uprawnionych podmiotów.
                  Dostęp do danych mogą mieć podmioty realizujące usługi na
                  rzecz Administratora takie jak np. usługi księgowe, usługi
                  programistyczne, dostawcy usług poczty elektronicznej.
                </Typography>
              </li>
              <li className="ml-3 space-y-1.5">
                <Typography as="h3" variant="body-text-1">
                  OKRES PRZECHOWYWANIA DANYCH OSOBOWYCH
                </Typography>
                <Typography as="p" variant="body-text-2" className="ml-2">
                  Dane osobowe są przetwarzane przez Administratora przez czas,
                  który jest niezbędny aby wykonać Usługę bądź do chwili
                  rozwiązania Umowy, rezygnacji z Usługi czy wniesienia
                  sprzeciwu wobec ich przetwarzania przez osobę, której dane
                  dotyczą.
                </Typography>
                <Typography as="p" variant="body-text-2" className="ml-2">
                  Po upłynięciu w/w okresów dane osobowe są przechowywane przez
                  okres oraz dla celów wymaganych przez przepisy prawne lub w
                  celach zabezpieczenia ewentualnych roszczeń.
                </Typography>
                <Typography as="p" variant="body-text-2" className="ml-2">
                  Dane osobowe, które są niezbędne do celów w ramach marketingu
                  bezpośredniego są przetwarzane do chwili złożenia u
                  Administratora wycofania zgody Użytkownika.
                </Typography>
              </li>
              <li className="ml-3 space-y-1.5">
                <Typography as="h3" variant="body-text-1">
                  PRAWA UŻYTKOWNIKA DOT. PRZETWARZANIA DANYCH OSOBOWYCH
                </Typography>
                <Typography as="p" variant="body-text-2" className="ml-2">
                  Użytkownik posiada prawo dostępu do swoich danych osobowych.
                  Ma prawo żądać od Administratora ich sprostowania,
                  ograniczenia, usunięcia, przenoszenia. Ma także prawo do
                  wniesienia sprzeciwu wobec ich przetwarzania.
                </Typography>
                <Typography as="p" variant="body-text-2" className="ml-2">
                  Użytkownik może w każdej chwili dokonać wycofania zgody na
                  przetwarzanie swoich danych osobowych. Możliwe jest wycofanie
                  wyrażonej zgody na otrzymywanie drogą elektroniczną informacji
                  handlowej.
                </Typography>
                <Typography as="p" variant="body-text-2" className="ml-2">
                  Użytkownik ma prawo do przenoszenia swoich danych, a
                  Administrator dostarcza je w formacie, który daje możliwość
                  odczytu maszynowego. Takie dane Użytkownik może przekazać
                  innemu Administratorowi oprócz tych danych, które stanowią
                  tajemnicę przedsiębiorstwa.
                </Typography>
              </li>
              <li className="ml-3 space-y-1.5">
                <Typography as="h3" variant="body-text-1">
                  PRAWO DO WNIESIENIA SKARGI
                </Typography>
                <Typography as="p" variant="body-text-2" className="ml-2">
                  Użytkownik ma prawo wnieść skargę do organu nadzorczego, jakim
                  w Polsce jest Prezes Urzędu Ochrony Danych Osobowych.
                </Typography>
              </li>
              <li className="ml-3 space-y-1.5">
                <Typography as="h3" variant="body-text-1">
                  INFORMACJA O OBOWIĄZKU LUB DOBROWOLNOŚCI PODANIA DANYCH
                  OSOBOWYCH
                </Typography>
                <Typography as="p" variant="body-text-2" className="ml-2">
                  Podanie przez Użytkownika swoich danych osobowych jest
                  całkowicie dobrowolne, ale jest to konieczne do zrealizowania
                  w sposób należy oferowanej usługi.
                </Typography>
              </li>
            </ol>
          </div>
          <div className="space-y-3">
            <Typography as="h2" variant="header-3">
              § II Informacje pozostałe
            </Typography>
            <ol className="ml-4 list-outside list-decimal space-y-3">
              <li className="ml-3 space-y-1.5">
                <Typography as="h3" variant="body-text-1">
                  RODZAJE KOMUNIKACJI I PRZETWARZANIE DANYCH W CELACH
                  KONTAKTOWYCH
                </Typography>
                <Typography as="p" variant="body-text-2" className="ml-2">
                  Administrator ma prawo do kontaktu z Użytkownikiem m.in. w
                  związku z marketingiem produktów i usług, odpowiedzi na
                  zapytanie, przeprowadzeniem badań i ankiet, w sprawie
                  realizacji usługi.
                </Typography>
                <Typography as="p" variant="body-text-2" className="ml-2">
                  Podstawą do przetwarzania danych jest wyrażona dobrowolnie
                  przez Użytkownika zgoda, którą w dowolnym momencie ma prawo
                  wycofać - informując o tym Administratora ustnie lub pisemnie.
                </Typography>
                <Typography as="p" variant="body-text-2" className="ml-2">
                  Dane w celach komunikacyjnych są przetwarzane do chwili
                  wycofania zgody przez Użytkownika. Administrator zastrzega, że
                  mogą pojawić się sytuacje, w których w wyniku obowiązywania
                  przepisów prawa ma on nakaz na przetwarzanie danych przez dany
                  czas.
                </Typography>
              </li>
              <li className="ml-3 space-y-1.5">
                <Typography as="h3" variant="body-text-1">
                  ZMIANA POLITYKI PRYWATNOŚCI
                </Typography>
                <Typography as="p" variant="body-text-2" className="ml-2">
                  Zmiany polityki prywatności są niezwłocznie publikowane na
                  podstronie Serwisu “Polityka Prywatności”.
                </Typography>
              </li>
              <li className="ml-3 space-y-1.5">
                <Typography as="h3" variant="body-text-1">
                  ZAMÓWIENIE ROZMOWY TELEFONICZNEJ
                </Typography>
                <Typography as="p" variant="body-text-2" className="ml-2">
                  Użytkownik za pomocą specjalnych miejsc w Serwisie ma
                  możliwość zamówienia bezpłatnej rozmowy telefonicznej oraz
                  zostawienia wiadomości. Administrator gromadzi pozostawione w
                  tych miejscach numery telefonów oraz adresy e-mail, a celem
                  ich przetwarzania jest nawiązanie kontaktu z Użytkownikiem, a
                  podstawą jest wyrażona przez niego zgoda. Taką zgodę
                  Użytkownik może w dowolnej chwili wycofać.
                </Typography>
                <Typography as="p" variant="body-text-2" className="ml-2">
                  Zgromadzone w trakcie rozmowy dane mogą zostać przekazane
                  Partnerom Administratora, a ich przekazaniu Użytkownik jest
                  informowany przez Konsultanta Call Center.
                </Typography>
                <Typography as="p" variant="body-text-2" className="ml-2">
                  Rozmowy odbywające się za pośrednictwem infolinii lub
                  podmiotów realizujących telefoniczną obsługę klienta są
                  nagrywane. Nagrywanie rozmów ma miejsce z uwagi na uzasadniony
                  interes Administratora - ochronę przed oszustwami,
                  roszczeniami oraz w trosce o jakość oferowanych usług.
                </Typography>
                <Typography as="p" variant="body-text-2" className="ml-2">
                  Nagrania rozmów są przechowywane przez Administratora przez
                  niezbędny okres, tj. do chwili, gdy nie ma możliwości prawnych
                  do występowania z roszczeniami dot. realizowanych w ramach
                  rozmowy usług. Okres ten może być dłuższy - w przypadkach,
                  które regulują przepisy prawne.
                </Typography>
              </li>
            </ol>
          </div>
          <div className="space-y-3">
            <Typography as="h2" variant="header-3">
              § III Polityka Cookies
            </Typography>
            <ol className="ml-4 list-outside list-decimal space-y-3">
              <li className="ml-3 space-y-1.5">
                <Typography as="h3" variant="body-text-1">
                  WYJAŚNIENIE OGÓLNE
                </Typography>
                <Typography as="p" variant="body-text-2" className="ml-2">
                  Serwis sprawdz-kosztorys.pl wykorzystuje pliki cookies, aby
                  działanie Serwisu było usprawnione oraz ułatwiona została
                  nawigacja Serwisu. Pliki cookies, zwane inaczej “ciasteczkami”
                  to pliki tekstowe. Są one zapisywane przez system
                  teleinformatyczny Usługodawcy w systemie teleinformatycznym
                  Użytkownika.
                </Typography>
                <Typography as="p" variant="body-text-2" className="ml-2">
                  Celem zapisu plików jest identyfikacja Użytkownika w Serwisie
                  oraz zbieranie ogólnych informacji o sposobie użytkowania
                  Serwisu. “Ciasteczka” nie zawierają informacji związanych z
                  danymi osobowymi.
                </Typography>
                <Typography as="p" variant="body-text-2" className="ml-2">
                  Użytkownik w każdej chwili może wyłączyć możliwość zapisywania
                  plików cookies. Aby to zrobić należy wejść w ustawienia
                  przeglądarki internetowej. Wyłączenie ciasteczek nie wiąże się
                  z brakiem możliwości korzystania z Serwisu, ale mogą pojawić
                  się utrudnienia czy to w użytkowaniu z zasobów Serwisu czy
                  korzystaniu z usług świadczonych w ramach Serwisu.
                </Typography>
              </li>
              <li className="ml-3 space-y-1.5">
                <Typography as="h3" variant="body-text-1">
                  CEL WYKORZYSTANIA PLIKÓW COOKIES
                </Typography>
                <Typography as="p" variant="body-text-2" className="ml-2">
                  Serwis sprawdz-kosztorys.pl wykorzystuje pliki cookies w
                  takich celach jak:
                  <ul className="ml-8 list-outside list-disc">
                    <li>
                      prowadzenie statystyk oraz analiz mających na celu
                      zobrazowanie w jaki sposób z serwisu korzystają
                      Użytkownicy, co jest niezbędne aby doskonalić jego zasoby
                      i dopasowywać je do potrzeb Użytkowników;
                    </li>
                    <li>optymalizacja stron internetowych;</li>
                    <li>
                      sprawdzania skuteczności prowadzonych działań z zakresu
                      marketingu, odpowiednie dopasowanie reklam, zapamiętywanie
                      czy dany Użytkownik zgodził się na m.in. pokazywanie
                      treści.
                    </li>
                  </ul>
                </Typography>
              </li>
              <li className="ml-3 space-y-1.5">
                <Typography as="h3" variant="body-text-1">
                  RODZAJE PLIKÓW COOKIES
                </Typography>
                <Typography as="p" variant="body-text-2" className="ml-2">
                  Serwis sprawdz-kosztorys.pl stosuje pliki cookies stałe oraz
                  sesyjne. Pierwszy rodzaj “ciasteczek” jak sama nazwa wskazuje
                  pozostają na urządzeniu Użytkownika do chwili albo ręcznego
                  usunięcia lub przez określony czas. Pliki sesyjne pozostają na
                  urządzeniu do momentu opuszczenia Serwisu przez Użytkownika
                  bądź wyłączenia przeglądarki internetowej.
                </Typography>
                <Typography as="p" variant="body-text-2" className="ml-2">
                  Za pośrednictwem plików cookies zbierane są dane o
                  statystycznym charakterze i mające za zadanie poprawienie
                  komfortu korzystania z Serwisu. “Ciasteczka” nie gromadzą
                  danych mogących zidentyfikować Użytkownika jako daną osobę.
                </Typography>
              </li>
              <li className="ml-3 space-y-1.5">
                <Typography as="h3" variant="body-text-1">
                  ODNOŚNIKI DO INNYCH STRON
                </Typography>
                <Typography as="p" variant="body-text-2" className="ml-2">
                  Serwis może zawierać odnośniki kierujące Użytkownika do innych
                  witryn internetowych. Administrator nie bierze
                  odpowiedzialności za zasady Polityki Prywatności obowiązujące
                  na innych stronach.
                </Typography>
              </li>
              <li className="ml-3 space-y-1.5">
                <Typography as="h3" variant="body-text-1">
                  ZMIANA DOT. USTAWIEŃ W WYSZUKIWARKACH
                </Typography>
                <Typography as="p" variant="body-text-2" className="ml-2">
                  Użytkownik może zmienić ustawienia związane z prywatnością w
                  wyszukiwarce, z której korzysta. Jest możliwość każdorazowego
                  informowania o lokowaniu plików cookies w urządzeniu
                  Użytkownika oraz ich automatycznemu blokowaniu.
                </Typography>
                <Typography as="p" variant="body-text-2" className="ml-2">
                  Szczegółowe informacje o obsłudze plików cookies można znaleźć
                  informacje w ustawieniach przeglądarek internetowych. Poniżej
                  zamieszczamy linki do takich informacji dot.
                  najpopularniejszych przeglądarek i urządzeń:
                  <ul className="ml-8 list-outside list-disc">
                    <li>
                      <a
                        rel="nofollow noreferrer"
                        target="_blank"
                        href="https://support.microsoft.com/pl-pl/help/278835/how-to-delete-cookie-files-in-internet-explorer"
                      >
                        Internet Explorer
                      </a>
                    </li>
                    <li>
                      <a
                        rel="nofollow noreferrer"
                        target="_blank"
                        href="http://support.google.com/chrome/bin/answer.py?hl=pl&amp;answer=95647"
                      >
                        Chrome
                      </a>
                    </li>{' '}
                    <li>
                      <a
                        rel="nofollow noreferrer"
                        target="_blank"
                        href="http://support.apple.com/kb/PH5042"
                      >
                        Safari
                      </a>
                    </li>
                    <li>
                      <a
                        rel="nofollow noreferrer"
                        target="_blank"
                        href="http://support.mozilla.org/pl/kb/Włączanie i wyłączanie obsługi ciasteczek"
                      >
                        Firefox
                      </a>
                    </li>
                    <li>
                      <a
                        rel="nofollow noreferrer"
                        target="_blank"
                        href="http://help.opera.com/Windows/12.10/pl/cookies.html"
                      >
                        Opera
                      </a>
                    </li>
                    <li>
                      <a
                        rel="nofollow noreferrer"
                        target="_blank"
                        href="http://support.google.com/chrome/bin/answer.py?hl=pl&amp;answer=95647"
                      >
                        Android
                      </a>
                    </li>
                    <li>
                      <a
                        rel="nofollow noreferrer"
                        target="_blank"
                        href="http://support.apple.com/kb/HT1677?viewlocale=pl_PL"
                      >
                        Safari (iOS)
                      </a>
                    </li>
                    <li>
                      <a
                        rel="nofollow noreferrer"
                        target="_blank"
                        href="http://www.windowsphone.com/pl-pl/how-to/wp7/web/changing-privacy-and-other-browser-settings"
                      >
                        Windows Phone
                      </a>
                    </li>
                  </ul>
                </Typography>
              </li>
            </ol>
          </div>
        </div>
      </div>
      <Steps />
    </Layout>
  );
};

export const Head = () => {
  return (
    <Seo title={PrivacyPage.title} description={PrivacyPage.description} />
  );
};

export default Page;
